<template>
  <div class="main-wrapper">
    <nav
      class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top"
      id="sideNav"
    >
      <a class="navbar-brand js-scroll-trigger" href="#about">
        <span class="d-block d-lg-none">Portfolio</span>
        <span class="d-none d-lg-block">
          <img
            class="img-fluid img-profile rounded-circle mx-auto mb-2"
            src="./assets/profile.jpg"
            alt=""
          />
        </span>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#about">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#experience"
              >Experience</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#education"
              >Education</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#skills">Skills</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#projects">Projects</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#interests"
              >Interests</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#awards"
              >Certifications</a
            >
          </li>
        </ul>
      </div>
    </nav>

    <div class="container-fluid p-0">
      <section class="resume-section p-3 p-lg-5 d-flex d-column" id="about">
        <div class="my-auto">
          <h1 class="mb-0">
            {{ name }}
            <span class="text-primary">{{ surname }}</span>
          </h1>
          <div class="subheading mb-5">
            {{ address }}
            <a href="mailto:baptiste@beduneau.com">{{ email }}</a> -
            <a
              href="./files/publickey.baptiste@beduneau.com.asc"
              download=""
              class="ml-2"
              >PGP KEY</a
            >
          </div>
          <p class="mb-5 text-block">{{ aboutMe }}</p>
          <ul class="list-inline list-social-icons mb-0">
            <li class="list-inline-item">
              <a
                href="https://www.linkedin.com/in/baptiste-beduneau/?locale=en_US"
              >
                <span class="fa-stack fa-lg">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="fa fa-linkedin fa-stack-1x fa-inverse"></i>
                </span>
              </a>
            </li>
            <li class="list-inline-item">
              <a href="https://github.com/BaptisteBdn">
                <span class="fa-stack fa-lg">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="fa fa-github fa-stack-1x fa-inverse"></i>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section
        class="resume-section p-3 p-lg-5 d-flex flex-column"
        id="experience"
      >
        <div class="my-auto">
          <h2 class="mb-5">Experience</h2>

          <div
            class="resume-item d-flex flex-column flex-md-row mb-5"
            v-for="job in experience"
            :key="job.description"
          >
            <div class="resume-content mr-auto">
              <h3 class="mb-0">{{ job.position }}</h3>
              <div class="subheading mb-3">{{ job.company }}</div>
              <div class="text-block">{{ job.description }}</div>
            </div>
            <div class="resume-date text-md-right">
              <span class="text-primary"
                >{{ job.startDate }} - {{ job.endDate }}</span
              >
            </div>
          </div>
        </div>
      </section>

      <section
        class="resume-section p-3 p-lg-5 d-flex flex-column"
        id="education"
      >
        <div class="my-auto">
          <h2 class="mb-5">Education</h2>
          <div
            class="resume-item d-flex flex-column flex-md-row mb-5"
            v-for="job in education"
            :key="job.description"
          >
            <div class="resume-content mr-auto">
              <h3 class="mb-0">{{ job.position }}</h3>
              <div class="subheading mb-3">{{ job.company }}</div>
              <div class="text-block">{{ job.description }}</div>
            </div>
            <div class="resume-date text-md-right">
              <span class="text-primary"
                >{{ job.startDate }} - {{ job.endDate }}</span
              >
            </div>
          </div>
        </div>
      </section>

      <section class="resume-section p-3 p-lg-5 d-flex flex-column" id="skills">
        <div class="my-auto">
          <h2 class="mb-5">Skills</h2>

          <div class="subheading mb-3">Job related skills</div>
          <ul style="list-style-type: disc;">
            <li> DevOps mindset, constant pursuit of opportunities to automate manual tasks</li>
            <li> Experience with high availability clusters, operational support and server management</li>
            <li> Cybersecurity practices and DevSecOps</li>
            <li> Knowledge of DNS, Load Balancing, SSL, TCP/IP, networking</li>
            <li> Strong problem-solving and troubleshooting abilities</li>
            <li> Communication and documentation best practices</li>
            <li> Agile software development</li>
          </ul>

          <div class="subheading mb-3">Tools &amp; Programming Languages</div>
          <ul style="list-style-type: disc;">
            <li> Scripting (Python, Shell) and coding (Java) skills</li>
            <li> Database experience (MySQL, Postgresql, Clickhouse)</li>
            <li> Cloud infrastructure Knowledge (Docker, LXC, Terraform, AWS)</li>
            <li> Infrastructure monitoring (Nagios, Prometheus, Grafana)</li>
            <li> Automation management (Ansible, Jenkins)</li>
            <li> Source control (Git, Mercurial)</li>
            <li> Logs management (ELK, Graylog)</li>
          </ul>
        </div>
      </section>

      <section
        class="resume-section p-3 p-lg-5 d-flex flex-column"
        id="projects"
      >
        <div class="my-auto">
          <h2 class="mb-5">Projects</h2>
          <div
            class="resume-item d-flex flex-column flex-md-row mb-5"
            v-for="job in projects"
            :key="job.description"
          >
            <div class="resume-content mr-auto">
              <h3 class="mb-0"><a :href="job.url">{{ job.position }}</a></h3>
              <div class="subheading mb-3">{{ job.company }}</div>
              <div class="text-block">{{ job.description }}</div>
            </div>
            <div class="resume-date text-md-right">
              <span class="text-primary"
                >{{ job.startDate }} - {{ job.endDate }}</span
              >
            </div>
          </div>
        </div>
      </section>

      <section
        class="resume-section p-3 p-lg-5 d-flex flex-column"
        id="interests"
      >
        <div class="my-auto">
          <h2 class="mb-5">Interests</h2>
          <ul style="list-style-type: disc;">
            <li>Video games (RPGs, competitive games)</li>
            <li>Climbing (Bouldering)</li>
            <li>Biking (Moutain and Gravel)</li>
            <li>Cooking</li>
            <li>Linux & i3</li>
            <li>CTFs</li>
          </ul>
        </div>
      </section>

      <section class="resume-section p-3 p-lg-5 d-flex flex-column" id="awards">
        <div class="my-auto">
          <h2 class="mb-5">Certifications</h2>
          <div
            class="resume-item d-flex flex-column flex-md-row mb-5"
            v-for="job in certification"
            :key="job.company"
          >
            <div class="resume-content mr-auto">
              <h3 class="mb-0">{{ job.position }}</h3>
              <div class="subheading mb-3">{{ job.company }}</div>
            </div>
            <div class="resume-date text-md-right">
              <span class="text-primary">{{ job.startDate }}</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "BAPTISTE",
      surname: "BEDUNEAU",
      email: "baptiste@beduneau.com",
      aboutMe:
        "DevOps enthusiast, I aspire to automate every project and tool I work on.",
      experience: [
        {
          position: "SECURITY ENGINEER - CERT",
          company: "Nameshield group",
          description:
            "Member and co-creator of Nameshield CERT team, providing incident handling, security consulting and awareness building in an ISO-27001 company. \nSpecialized in assisting the development teams by providing a vision of threats, vulnerabilities and countermeasures. \nPerformed cybersecurity audits on the web applications. \nDeveloped security task automation to optimize and improve efficiency.",
          startDate: "aug. 2021",
          endDate: "today",
        },
        {
          position: "SITE RELIABILITY ENGINEER",
          company: "Nameshield group",
          description:
            "Developed cybersecurity automation and analysis tools. \nSetup and maintained applications in a containerized environment. \nIncluding software development (Python, Java, PHP, Javascript ES6+) and system administration in a mostly on-premise infrastructure (Bash, LXC, ZFS, Ansible). \nAssisted in designing an AWS analysis application (EC2, lambda, Kinesis) with IaC (Terraform). \nDocumentation, analysis and drafting of projects within a team of 10 sysadmins with the goal of improving visibility and prioritization in an Agile environment.",
          startDate: "sept. 2019",
          endDate: "aug. 2021",
        },
        {
          position: "SOFTWARE ENGINEER",
          company: "Nameshield group",
          description:
            "Designed and developed a cybersecurity tool intended to detect and prevent domain name anomalies. \nIncluding languages such as Java and Python and using DNS technologies and research.",
          startDate: "sept. 2018",
          endDate: "sept. 2019",
        },
        {
          position: "SOFTWARE ENGINEER INTERN",
          company: "Innoseis",
          description:
            "Four-month internship in the NIKHEF group as a software engineer. \nOperation and testing of a GPS module. \nImplementation of a Windows application to manage and visualize a software database.",
          startDate: "aug. 2017",
          endDate: "nov. 2017",
        },
      ],
      education: [
        {
          position: "ESEO Angers",
          company: "Engineering degree in Computer Science",
          description: "Cloud Computing major",
          startDate: "2014",
          endDate: "2019",
        },
      ],
      projects: [
        {
          position: "Docker self-hosted Apps",
          url: "https://github.com/BaptisteBdn/docker-selfhosted-apps",
          company: "Complete guide with examples to selfhosting using docker",
          description: "Community-first guide to selfhosting with docker and traefik at its core. Containing security measures, automation with ansible, tested backups and updates as well as a multitude of services.",
          startDate: "jan. 2022",
          endDate: "today",
        },
        {
          position: "Custom linux dotfiles",
          url: "https://github.com/BaptisteBdn/dotfiles",
          company: "Linux custom theming using i3",
          description: "Automated theme change with pywal and rounded corners for a minimalist look, featuring EndeavourOS, i3-gaps, pywal and rounded corners.",
          startDate: "nov. 2021",
          endDate: "nov. 2021",
        },
        {
          position: "Budgeter",
          url: "https://github.com/BaptisteBdn/Budgeter",
          company: "Budget web-app to help families manage their expenses",
          description: "Responsive webapp built with VueJS and NodeJs (Express). Including lots of dashboards. ",
          startDate: "feb. 2021",
          endDate: "jun. 2021",
        },
        {
          position: "BF",
          url: "https://github.com/ProjectBlackFalcon",
          company: "Creation of an AI with a complex behavior",
          description: "Work within a search group looking to develop a artificial life within a mainly human virtual environment. \nThis project involved online video game reverse engineering, machine learning, multi-language development and database management.",
          startDate: "sept. 2017",
          endDate: "jul. 2019",
        },
        {
          position: "vSquare",
          url: "https://github.com/BaptisteBdn/VSquare-API",
          company: "Centralized solution for VM management",
          description: "VSquare is a projet based on VSphere. The goal was to create a better, simpler client while adding useful resources, such as LDAP authentication.",
          startDate: "jan. 2018",
          endDate: "jun. 2018",
        },
      ],
      certification: [
        {
          position: "AWS Certified Solutions Architect - Associate",
          company: "AWS",
          startDate: "2021",
        },
        {
          position: "Voltaire",
          company: "Certificat Voltaire",
          startDate: "2017",
        },
        {
          position: "TOEIC (960/990)",
          company: "The TOEIC Program",
          startDate: "2016",
        }
      ],
    };
  },
  mounted() {
    const $ = this.jquery;
    this.$nextTick(() => {
      // our custom jQuery code goes here
      $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
        if (
          location.pathname.replace(/^\//, "") ===
            this.pathname.replace(/^\//, "") &&
          location.hostname === this.hostname
        ) {
          var target = $(this.hash);
          target = target.length
            ? target
            : $("[name=" + this.hash.slice(1) + "]");
          if (target.length) {
            $("html, body").animate(
              {
                scrollTop: target.offset().top,
              },
              500,
              "easeInOutExpo"
            );
            return false;
          }
        }
      });

      // Closes responsive menu when a scroll trigger link is clicked
      $(".js-scroll-trigger").click(function () {
        $(".navbar-collapse").collapse("hide");
      });

      // Activate scrollspy to add active class to navbar items on scroll
      $("body").scrollspy({
        target: "#sideNav",
      });
    });
  },
};
</script>

<style>
@import "./assets/styles/resume.css";
</style>